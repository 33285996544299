<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <WindowTitleBar title="Work Order Creation" @loadData="loadData" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Process</label>
            <select  id="cmbprocess" class="form-control" autofocus="" required=""  v-if="workorder"  v-model="workorder.process_id" >
              <option value="305" selected>Weaving</option>
              <option value="350">Knitting</option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="workorder" v-model="workorder.doc_date">
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Jobworker</label>
            <select id="cmbjobworker" class="form-control select" required="" v-if="workorder"  v-model="workorder.jobworker_id" data-live-search="true">
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6"></div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Order No</label>
            <input type="number" id="txtrefno" class="form-control" placeholder="Order No" min="0" v-if="workorder" v-model="workorder.order_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Due Date</label>
            <input type="date" class="form-control" v-if="workorder" v-model="workorder.due_date" placeholder="Due Date" >
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered" >
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Quality</th>
              <th >Description</th>
              <th style="width:75px; text-align: right;">Qty</th>
              <th style="width:100px; text-align: right;">Rate/Mtr</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-for="(detail,index) in workorder.list" >
                <DetailRow v-if="index >= 0"  :myqualities="qualities" :myitems="items"   :myrow="detail" :myindex="index" @onRemoveRow="removeRow(index)"></DetailRow>
            </tbody>
            <tfoot>
              <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="workorder" v-model="workorder.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-4">
          <h3 style="font-size: 48px;color: blue">

          </h3>
        </div>

        <div class="col-md-2">
          <div class="form-group row" >
            <label class="col-sm-7 col-form-label text-right">Rate Type:</label>
            <div class="col-sm-5">
              <select class="form-control" v-if="workorder" v-model="workorder.rate_type">
                <option value="0">Base Unit</option>
                <option value="1">Kgs</option>
              </select>
            </div>
          </div>

        </div>

        <div class="col-md-3 text-right" >

          <div class="form-group row" >
            <label class="col-md-4 col-form-label">Handhold:</label>

            <div class="col-md-4">
              <select class="form-control" v-if="workorder" v-model="workorder.handhold_type">
                <option value="0">Percent</option>
                <option value="1">Fixed Rate</option>
              </select>
            </div>

            <div class="col-md-4">
              <input type="number" class="form-control" placeholder="HandHold %" min="0" v-if="workorder" v-model="workorder.handhold_value">
            </div>
          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>

      </div>
    </div>
    </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import ItemBox from '@/components/core/ItemBox.vue'
  import DetailRow from './WorkOrderDetailRow.vue'
  import { store } from '@/store/store.js'
  import WindowTitleBar from '../../../components/core/WindowTitleBar'

  export default {
    name: 'WorkOrderForm',
    components: {
      WindowTitleBar,
      ItemBox,
      DetailRow
    },
    store,
    props: {
      myworkorder: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":0,"type":0,"version":0,"finyear":0,"process":{"id":0,"name":"","nature":0},"jobworker":{"id":0,"type":0,"name":""},"process_id":0,"jobworker_id":0,"workorder_no":0,"doc_date":"0001-01-01","order_no":"","due_date":"0001-01-01","remarks":"","rate_type":0,"handhold_type":0,"handhold_value":0,"list":[]}')
      },
      editmode:{
        type:Boolean,
        default: () => false,
      }
    },
    beforeMount () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      if( this.myworkorder.id > 0 ) {
        var data = [];
        const request = async () => {
          const response = await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/process/${this.myworkorder.process.id}/`, requestOptions);

          const json = await response.json();
          data = json.data;
          if (_.isArray(data)) {
            data.forEach(function (itm) {
              self.$data.items.set(itm.id, itm);
            });
          }
          this.workorder = this.myworkorder;
        }
        request();
      }

    },
    data () {
      return {
        readonly: false,
        isEditMode:false,
        isLoaded:false,
        processes:[],
        ledgers:[],
        detailItems:[],
        items : new Map(),
        workorder: JSON.parse('{"id":0,"status":0,"type":0,"version":0,"finyear":0,"process":{"id":0,"name":"","nature":0},"jobworker":{"id":0,"type":0,"name":""},"process_id":0,"jobworker_id":0,"workorder_no":0,"doc_date":"0001-01-01","order_no":"","due_date":"0001-01-01","remarks":"","rate_type":0,"handhold_type":0,"handhold_value":0,"list":[]}'),
        qualities:[],
      }
    },
    watch:{
      isLoaded: function (val) {
        if(this.isEditMode){
          // this.workorder = this.myworkorder;
        }
      },
    },
    created () {
      const self = this;

      // self.$data.workorder.doc_date = moment().format('YYYY-MM-DD');
      // self.$data.workorder.due_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      self.loadAll();
      self.$data.workorder.doc_date = moment().format('YYYY-MM-DD');
      self.$data.workorder.due_date = moment().format('YYYY-MM-DD');

      if (self.$data.workorder.id == 0){
        self.$data.workorder.doc_date = moment().format('YYYY-MM-DD');
        self.$data.workorder.due_date = moment().format('YYYY-MM-DD');
      }

      $('#cmbprocess').focus();
    },
    methods:{
      setTitle() {
        return this.workorder.id == 0 ? "Workorder Creation" : "Doc No: " + this.workorder.doc_no + "  Workorder Updation";
      },
      loadAll(){
        this.loadJobworkers();
        this.loadQualities();
      },
      closeThis(){
        this.$emit('workorder_window_closed');
        if(this.workorder.id < 2){
          this.$router.push("/")
        }
      },
      addRow(){
        const self = this;
        self.$data.workorder.list.push(JSON.parse('{"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"rate":0,"remarks":""}'));
          setTimeout(function(){
            $("tbody>tr").last().find("td:eq(1) select").focus();
          }, 99);
      },
      loadProcesses(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.processes = [
          {"id":305,"name":"Weaving"},
          {"id":350,"name":"Knitting"}
        ];
      },
      loadQualities(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(Array.isArray(resp.data)){
              self.$data.qualities = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      clear(){
        const self = this;
        // alert(self.$data.workorder.process.id);
        self.$data.workorder = JSON.parse('{"id":0,"status":0,"type":0,"version":0,"finyear":0,"process":{"id":0,"name":"","nature":0},"jobworker":{"id":0,"type":0,"name":""},"process_id":0,"jobworker_id":0,"workorder_no":0,"doc_date":"0001-01-01","order_no":"","due_date":"0001-01-01","remarks":"","rate_type":0,"handhold_type":0,"handhold_value":0,"list":[]}');
        self.$data.workorder.list = [];

        self.$data.workorder.doc_date = moment().format('YYYY-MM-DD');
        self.$data.workorder.due_date = moment().format('YYYY-MM-DD');
      },
      loadItems(processid) {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.items = new Map();
        self.$data.detailItems = [];
        if( processid > 0 && !self.$data.isEditMode) {

          fetch(`${process.env.VUE_APP_ROOT_API}api/items/process/${processid}`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              if( _.isArray (resp.data)){
                self.$data.detailItems = resp.data;
                self.$data.detailItems.forEach(function (itm) {
                  self.$data.items.set(itm.id, itm);
                });
                self.$data.isLoaded = true;
              }
            }else{
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          }).finally(function () {
            //$(".select-search").select2();
          });
        }
      },
      loadJobworkers() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        $('#cmbjobworker').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`,requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#cmbjobworker').unblock();
          if (resp.ok){
            if(resp.data != null){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(function () {
          //$('#cmbjobworker').unblock();
        });

      },
      removeRow(index){
        if (index > -1) {
          this.$data.workorder.list.splice(index, 1);
        }
      },
      saveVoucher(){
        const self = this;

        try {

          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });


          let myworkorder = JSON.parse(JSON.stringify(self.$data.workorder));
          myworkorder.finyear = store.state.user.finyear;
          myworkorder.status = "Active";
          myworkorder.cmp_id = self.$store.state.user.company.id;
          myworkorder.doc_date = moment(self.$data.workorder.doc_date).format('YYYY-MM-DD');
          myworkorder.due_date = moment(self.$data.workorder.due_date).format('YYYY-MM-DD');
          myworkorder.order_no = self.$data.workorder.order_no.toString();
          myworkorder.process_id = parseInt(myworkorder.process_id);
          myworkorder.jobworker_id = parseInt(myworkorder.jobworker_id);
          myworkorder.rate_type = parseInt(myworkorder.rate_type);
          myworkorder.handhold_type = parseInt(myworkorder.handhold_type);
          myworkorder.handhold_value = parseFloat(myworkorder.handhold_value);

          if (myworkorder.order_no.trim().length < 1) {
            alert("Invalid Order No");
            return;
          } else if (myworkorder.list.length == 0) {
            alert("Invalid WorkOrder Detail");
            return;
          } else if (parseInt(myworkorder.handhold_value) < 0) {
            alert('Invalid handhold value');
            return;
          } else if (_.isNull(myworkorder.rate_type)) {
            alert('Invalid Rate In Kgs or Mtr');
            return;
          } else if (myworkorder.process_id < 1) {
            alert('Invalid Process');
            return
          } else if (myworkorder.jobworker_id < 1) {
            alert('Invalid Jobworker');
            return
          }

          myworkorder.list.forEach(function (detail) {
            // detail.qty_txt = detail.qty.toString();
            detail.quality_id = detail.quality.id;
            detail.qty = parseFloat(detail.qty);
            detail.rate = parseFloat(detail.rate);

            if (parseInt(detail.quality.id) < 1) {
              alert('Invalid Quality');
              return;
            } else if (parseFloat(detail.rate) <= 0) {
              alert('Invalid Rate');
              return;
            }

          });


          const requestOptions = {
            method: (myworkorder.id == 0 ? 'POST' : 'PUT'),
            mode: 'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myworkorder)
          };

          fetch(`${process.env.VUE_APP_ROOT_API}api/workorder`, requestOptions).then(userService.handleResponse).then(function (resp) {
            $('#mycard').unblock();
            if (resp.ok) {
              self.clear();
              swal({
                title: 'Great', type: 'success', text: resp.msg, onClose: () => {
                  $('#cmbprocess').focus()
                }, timer: 1500
              });
              self.$emit('workorder_saved', resp.data);
            } else {
              swal({
                title: "Oops", text: resp.msg, type: "error", onClose: () => {
                  $('#cmbprocess').focus()
                }
              });
            }
          }).catch(function (err) {
            $('#mycard').unblock();
            swal({
              title: "Oops", text: err.toString(), type: "error", onClose: () => {
                $('#cmbprocess').focus()
              }
            });
          });

        }catch (e) {
          alert(e);
        }finally {

        }
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
