<template>
  <tr style="padding: 0px;">
    <td style="padding:0px; text-align: center;"> {{index + 1}} </td>
    <td style="padding: 0px;">
<!--      <ItemBox v-if="detail" v-bind:myitems="items" v-bind:myitem="detail.item"  v-on:itemchanged="onItemChanged"></ItemBox>-->
      <select class="form-control"  v-model="detail.quality.id">
        <option v-for="quality in myqualities" v-bind:value="quality.id">
          {{ quality.name }}
        </option>
      </select>
    </td>

    <td style="padding:0px;">
      <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />
    </td>

    <td style="padding:0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" />
    </td>

    <td style="padding:0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" />
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import ItemBox from '@/components/core/ItemBox.vue'
  export default {
    name: 'WorkOrderDetailRow',
    components: {
      ItemBox
    },
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"quality":{"id":"","code":0,"name":"","p_name":""},"qty":0,"weight":0,"rate":0,"remarks":""}')
      },
      myitems:{
        type: Map,
        default:() => new Map()
      },
      myqualities:{
        type: Array,
        default:() => [],
      },
      myindex:{
        type: Number,
        default:() => 0
      }
    },
    beforeMount () {
      this.detail = this.myrow;
      this.items = this.myitems;
      this.index = this.myindex;
    },
    mounted () {

    },
    data () {
      return {
        index: 0,
        detail : JSON.parse('{"quality":{"id":"","code":0,"name":"","p_name":""},"qty":0,"weight":0,"rate":0,"remarks":""}'),
        items: new Map(),
      }
    },
    methods:{
      onItemChanged(item){
        let self = this;
        self.$data.detail.item = item;
      },
      removeRow(){
        this.$emit('onRemoveRow', (this.$data.detail.idx * -1) );
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
